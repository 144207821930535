import { FormControl, makeStyles, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUsersRequest, selectUserRequest } from "../../store/user/user.actions";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(7),
    right: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 220,
    width: "100%",
    background: "white",
  },
}));

export default function UserControl() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [user, setUser] = useState(undefined);
  const { users, selectedUser } = useSelector((state) => {
    return {
      users: state.UserReducer.Users,
      selectedUser: state.UserReducer.selectedUser,
    };
  });

  useEffect(() => {
    if (users.length === 0) dispatch(loadUsersRequest());
  }, []);

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  return (
    <FormControl
      variant="outlined"
      color="secondary"
      className={classes.formControl}
    >
      <Select
        name="user"
        value={user}
        onChange={(e) => {
          dispatch(selectUserRequest(e.target.value));
        }}
        fullWidth
        labelId="user"
        id="user"
        margin="dense"
        displayEmpty
      >
        <MenuItem value={undefined}>Select User</MenuItem>
        {users.map((x) => {
          return <MenuItem value={x.id}>{x.tenantName}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}
